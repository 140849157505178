.btn-5 {
    width: 100px;
    height: 40px;
    line-height: 20px;
    padding: 0;
    border: none;
    color: #fff;
    background: rgb(255, 104, 87);
    background: linear-gradient(0deg, rgb(255, 117, 101) 0%, rgb(255, 119, 61) 100%);
}

.btn-5:hover {
    color: #f0094a;
    background: transparent;
    box-shadow: none;
}

.btn-5:before,
.btn-5:after {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    height: 2px;
    width: 0;
    background: #f0094a;
    box-shadow:
        -1px -1px 5px 0px #fff,
        7px 7px 20px 0px #0003,
        4px 4px 5px 0px #0002;
    transition: 400ms ease all;
}

.btn-5:after {
    right: inherit;
    top: inherit;
    left: 0;
    bottom: 0;
}

.btn-5:hover:before,
.btn-5:hover:after {
    width: 100%;
    transition: 800ms ease all;
}
.custom-btn {
    width: 150px;
    height: 40px;
    color: #000;
    border-radius: 10px;
    padding: 10px 25px;
    font-family: 'Lato', sans-serif;
    font-weight: 500;
    background: transparent;
    cursor: pointer;
    text-align: start;
    transition: all 0.3s ease;
    position: relative;
    display: inline-block;
    box-shadow: inset 2px 2px 2px 0px rgba(254, 250, 250, 0.5),
        1px 1px 10px 0px rgba(0, 0, 0, .1),
        4px 4px 5px 0px rgba(0, 0, 0, .1);
    outline: none;
}
.MuiButton-text {
    text-transform: none !important;
}