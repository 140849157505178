.testimonial-slider {
    padding: 10px;
    display: flex;
}
.testimonial {
    margin-bottom: 10px;
    border-radius: 5px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.testimonial h4 {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    font-family: "Roboto", sans-serif;
}

.testimonial p {
    font-size: 16px;
    color: #000000;
    text-align: center;
    padding: 10px;
    font-family: "Lato", sans-serif;
}


.slick-prev:before,
.slick-next:before {
    color: rgb(255, 94, 0);
}