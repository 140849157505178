/* this is the style for the container inside the app.js */
/* you can remove it and just use the card CSS */
.container {
  position: relative;
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(75, 53, 118);
  background: linear-gradient(
    219deg,
    rgba(75, 53, 118, 1) 33%,
    rgba(248, 177, 60, 1) 100%
  );
  font-family: "Roboto", sans-serif;
  gap: 50px;
}
/* ======================================================== */
.card {
  transform-style: preserve-3d;
  position: relative;
  padding: 25px;
  width: 350px;
  height: 500px;
  border-radius: 20px;
  border-color: #3b0000;
  border-width: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-around;
  background: linear-gradient(
    25deg,
    #ffffff 0%,
    #ffffff 56%
  );
  transition: all 0.1s ease;
  margin-top: 16px;
  margin-bottom: 16px;
}
.sneaaker-img {
  position: relative;
  width: 90%;
  transition: all 0.5s ease;
}
.title,
.card p {
  width: 100%;
  color: rgb(6, 6, 6);
}
.sizes-box {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 10px;
}
.sizes-box li {
  list-style: none;
  cursor: pointer;
  font-weight: bold;
  border-radius: 30px;
  padding: 0.5rem 1.5rem;
  background-color: rgb(220, 220, 220);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.sizes-box li:hover {
  background-color: rgb(134, 134, 134);
}
.button-box {
  width: 100%;
}
.purchase {
  cursor: pointer;
  width: 100%;
  border-radius: 30px;
  padding: 0.8rem 1.5rem;
  font-weight: bold;
  border: none;
  font-size: 1rem;
  letter-spacing: 1px;
  background-color: rgba(248, 177, 60, 1);
  box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
    rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
    rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.purchase:hover {
  background-color: rgb(222, 157, 54);
}
